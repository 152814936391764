import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser, logoutUserFromDaaraService } from '../actions/client';

const LogoutButton = (props) => (
    <li className={`dashboard__menu`}>
        <i className={props.icon}/>
        <button onClick={()=>props.logout()}>{props.title}</button>
    </li>
);

const mapDispatch = dispatch => ({
    logout: () => {
        dispatch(logoutUserFromDaaraService());
        dispatch(logoutUser());
    }
});

export default withRouter(connect(null, mapDispatch)(LogoutButton));