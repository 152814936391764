import {INVOICE_ACTION_TYPES} from './actionTypes'
import clientService from '../services/client';


//////////////////////// ACTIONS ///////////////////////////////////

export const getInvoices = () => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICES
    }
};

export const getInvoicesSuccess = (invoices) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICES_SUCCESS,
        data: invoices
    }
};

export const getInvoicesFailed = (error) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICES_FAIL,
        data: error
    }
};

export const getInvoiceByRefCode = () => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE
    }
};

export const getInvoiceByRefCodeSuccess = (invoice) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE_SUCCESS,
        data: invoice
    }
};

export const getInvoiceByRefCodeFailed = (error) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE_FAIL,
        data: error
    }
};

export const addEmployeeToInvoice = () => {
    return {
        type: INVOICE_ACTION_TYPES.ADD_EMPLOYEE_TO_INVOICE,
    }
};

export const addEmployeeToInvoiceSuccess = (employee) => {
    return {
        type: INVOICE_ACTION_TYPES.ADD_EMPLOYEE_TO_INVOICE_SUCCESS,
        data: employee
    }
};

export const addEmployeeToInvoiceFail = (error) => {
    return {
        type: INVOICE_ACTION_TYPES.ADD_EMPLOYEE_TO_INVOICE_FAIL,
        data: error
    }
};


//////////////////////// THUNKS ///////////////////////////////////

export const getClientInvoices = (accessToken, clientId) => {
    return ((dispatch)=>{
        dispatch(getInvoices());
        clientService.getInvoices(accessToken, clientId)
            .then((invoices)=>{
                dispatch(getInvoicesSuccess(invoices));
            }, (err)=>{
                dispatch(getInvoicesFailed({message:err.response.data.message, code:err.response.status}));
            });
    })
};

export const getClientInvoiceByRefCode = (refCode, accessToken, clientId) => {
    return ((dispatch)=>{
        dispatch(getInvoiceByRefCode());
        clientService.getInvoiceByReferenceCode(refCode, accessToken, clientId)
            .then((invoice)=>{
                dispatch(getInvoiceByRefCodeSuccess(invoice));
            }, (err) => {
                dispatch(getInvoiceByRefCodeFailed({message:err.response.data.message, code:err.response.status}));
            })
    })
};

export const addEmployeeToClientInvoice = (firstName, lastName, email, phone, comboId, accessToken) => {
    return ((dispatch)=>{
        dispatch(addEmployeeToInvoice());
        clientService.addSingleEmployee(firstName, lastName, email, phone, comboId, accessToken)
            .then((response)=>{
                dispatch(addEmployeeToInvoiceSuccess(response));
            }, (err) => {
                dispatch(addEmployeeToInvoiceFail({message:err.response.data.message, code:err.response.status}));
            })
    })
};