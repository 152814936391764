import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as staffActions from '../actions/staff';
import clientService from '../services/client';
import NotificationBanner from './boxes/NotificationBanner';
import Modal from './boxes/Modal';
import EditEmployeeForm from './forms/EditEmployeeForm';
import LoadingScreen from './LoadingScreen';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import MainContent from './boxes/MainContent';
import PageNotFound from './PageNotFound';
import USER_STUB from '../user_stub.jpeg';

class EmployeeDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            openUpdateProfileModal: false,
            openDeleteEmployeeModal: false
        };
    }

    componentDidMount() {
        this.getStaffDetails();
        clientService.getAllPlans().then((plans) => {
            this.setState({
                plans: plans
            });
        });
    }

    getStaffDetails() {
        const { dispatch, match, user } = this.props;
        dispatch(
            staffActions.getClientEmployeeById(
                match.params.staffId,
                user.credentials.accessToken,
                user.companyInfo.id
            )
        );
    }

    toggleEmployeeModal = () => {
        this.setState({
            openUpdateProfileModal: !this.state.openUpdateProfileModal
        });
    };

    toggleDeleteEmployeeModal = () => {
        this.setState({
            openDeleteEmployeeModal: !this.state.openDeleteEmployeeModal
        });
    };

    renderLoading() {
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen />
                </PageBody>
            </MainContent>
        );
    }

    DaaraEmployeeDetails(selectedStaff, plans) {
        return (
            <div className="dashboard_profile">
                <img
                    src={selectedStaff.profile ? selectedStaff.profile.picture : USER_STUB}
                    className="profile__img"
                    alt="Enrollee"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'
                    }}
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="profile__info" style={{ paddingBottom: '0' }}>
                            <p className="profile__label">FULL NAME</p>
                            <p className="profile__infotext">{`${selectedStaff.person.firstName} ${selectedStaff.person.lastName}`}</p>
                            <p className="profile__label">PACKAGE</p>
                            <p className="profile__infotext">{selectedStaff.product.name}</p>
                        </div>
                        <div className="profile__info" style={{ paddingBottom: '0' }}>
                            <p className="profile__label">EMAIL ADDRESS</p>
                            <p className="profile__infotext">{`${
                                selectedStaff.person.email || 'N/A'
                            }`}</p>
                            <p className="profile__label">PHONE NUMBER</p>
                            <p className="profile__infotext">{`${
                                selectedStaff.person.phoneNumber || 'N/A'
                            }`}</p>
                        </div>
                        <div className="profile__planinfo" style={{ paddingBottom: '0' }}>
                            <p className="profile__label">COVER START</p>
                            <p className="profile__infotext">
                                {moment(
                                    selectedStaff.subscriptionItems[0].subscription.startDate
                                ).format('dddd, MMMM Do YYYY')}
                            </p>
                            <p className="profile__label">COVER END</p>
                            <p className="profile__infotext">
                                {moment(
                                    selectedStaff.subscriptionItems[0].subscription.endDate
                                ).format('dddd, MMMM Do YYYY')}
                            </p>
                        </div>
                    </div>
                    {plans.length > 0 && selectedStaff.new_product && (
                        <p
                            style={{
                                padding: '20px',
                                fontSize: '1.4em',
                                color: 'grey'
                            }}
                        >
                            <span style={{ color: '#449802', textTransform: 'uppercase' }}>
                                {selectedStaff.new_product.name}
                            </span>{' '}
                            plan begins at the next cycle
                        </p>
                    )}
                    {!selectedStaff.profile && (
                        <button className="primary_button" onClick={this.toggleEmployeeModal}>
                            Edit Profile
                        </button>
                    )}
                </div>
                <br />
            </div>
        );
    }

    onFormSubmit = (values, dispatch) => {
        this.toggleEmployeeModal();
        dispatch(
            staffActions.editClientEmployee(
                values.user_id,
                values.first_name,
                values.last_name,
                values.email_address,
                values.phone_number,
                values.access_token,
                values.client_id
            )
        );
        this.getStaffDetails();
    };

    removeEnrollee = () => {
        this.toggleDeleteEmployeeModal();
        const { user, match, dispatch } = this.props;
        dispatch(
            staffActions.removeEnrolleeById(
                match.params.staffId,
                user.credentials.accessToken,
                user.companyInfo.id
            )
        );
    };

    render() {
        const { openUpdateProfileModal, openDeleteEmployeeModal, plans } = this.state;
        let { isFetching, selectedStaff, error, alert, user } = this.props;

        if (error && error.code === 404) return <PageNotFound />;

        if (!isFetching && !_.isEmpty(selectedStaff))
            return (
                <MainContent>
                    {openUpdateProfileModal && (
                        <EditEmployeeModal
                            onFormSubmit={this.onFormSubmit}
                            onClose={this.toggleEmployeeModal}
                            plans={plans}
                            user={selectedStaff}
                            clientId={user.companyInfo.id}
                            accessToken={user.credentials.accessToken}
                        />
                    )}

                    {openDeleteEmployeeModal && (
                        <DeleteEmployeeModal
                            onDeleteEmployee={this.removeEnrollee}
                            onClose={this.toggleDeleteEmployeeModal}
                            fullName={`${selectedStaff.first_name} ${selectedStaff.last_name}`}
                        />
                    )}

                    <PageHeader
                        title={`${selectedStaff.first_name} ${selectedStaff.last_name}'s Profile`}
                    />
                    <PageBody>
                        {(!_.isEmpty(error) || alert) && (
                            <NotificationBanner
                                title={error.message ? 'Error!' : 'Notification!'}
                                message={error.message ? error.message : alert}
                                type={error.message ? 'error' : 'success'}
                            />
                        )}
                        {selectedStaff.isDeleted && (
                            <div className="deleted-profile__info">ENROLLEE REMOVED</div>
                        )}
                        <div className="button-header">
                            {user.companyInfo.canModify && (
                                <button
                                    className="primary_button"
                                    style={{ background: '#b32727' }}
                                    onClick={this.toggleDeleteEmployeeModal}
                                >
                                    <i className="fa fa-trash" style={{ color: 'white' }} />
                                    {'  '}
                                    REMOVE ENROLLEE
                                </button>
                            )}
                        </div>
                        <div className="dashboard_profile">
                            <img
                                src={
                                    selectedStaff.profile
                                        ? selectedStaff.profile.picture
                                        : USER_STUB
                                }
                                className="profile__img"
                                alt="Enrollee"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className="profile__info" style={{ paddingBottom: '0' }}>
                                        <p className="profile__label">FULL NAME</p>
                                        <p className="profile__infotext">{`${selectedStaff.person.firstName} ${selectedStaff.person.lastName}`}</p>
                                        <p className="profile__label">PACKAGE</p>
                                        <p className="profile__infotext">
                                            {selectedStaff.product.name}
                                        </p>
                                    </div>
                                    <div className="profile__info" style={{ paddingBottom: '0' }}>
                                        <p className="profile__label">EMAIL ADDRESS</p>
                                        <p className="profile__infotext">{`${
                                            selectedStaff.person.email || 'N/A'
                                        }`}</p>
                                        <p className="profile__label">PHONE NUMBER</p>
                                        <p className="profile__infotext">{`${
                                            selectedStaff.person.phoneNumber || 'N/A'
                                        }`}</p>
                                    </div>
                                    <div
                                        className="profile__planinfo"
                                        style={{ paddingBottom: '0' }}
                                    >
                                        <p className="profile__label">COVER START</p>
                                        <p className="profile__infotext">
                                            {moment(
                                                selectedStaff.subscriptionItems[0].subscription
                                                    .startDate
                                            ).format('dddd, MMMM Do YYYY')}
                                        </p>
                                        <p className="profile__label">COVER END</p>
                                        <p className="profile__infotext">
                                            {moment(
                                                selectedStaff.subscriptionItems[0].subscription
                                                    .endDate
                                            ).format('dddd, MMMM Do YYYY')}
                                        </p>
                                    </div>
                                </div>
                                {plans.length > 0 && selectedStaff.new_product && (
                                    <p
                                        style={{
                                            padding: '20px',
                                            fontSize: '1.4em',
                                            color: 'grey'
                                        }}
                                    >
                                        <span
                                            style={{ color: '#449802', textTransform: 'uppercase' }}
                                        >
                                            {selectedStaff.new_product.name}
                                        </span>{' '}
                                        plan begins at the next cycle
                                    </p>
                                )}
                                {!selectedStaff.profile && (
                                    <button
                                        className="primary_button"
                                        onClick={this.toggleEmployeeModal}
                                    >
                                        Edit Profile
                                    </button>
                                )}
                            </div>
                            <br />
                        </div>
                    </PageBody>
                </MainContent>
            );
        return this.renderLoading();
    }
}

const EditEmployeeModal = (props) => {
    return (
        <Modal title="Edit Employee Details" onClose={props.onClose}>
            <EditEmployeeForm
                onSubmit={props.onFormSubmit}
                accessToken={props.accessToken}
                clientId={props.clientId}
                plans={props.plans}
                user={props.user}
            />
        </Modal>
    );
};

const DeleteEmployeeModal = ({ fullName, onDeleteEmployee, onClose }) => {
    return (
        <Modal title={`Delete Enrollee?`} onClose={onClose}>
            <div className="delete-enrollee-modal">
                <p>Are you sure you want to remove {fullName}?</p>
                <div className="button-wrapper">
                    <button className="yes" onClick={onDeleteEmployee}>
                        YES
                    </button>
                    <button className="no" onClick={onClose}>
                        NO
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = ({ staff }) => ({
    selectedStaff: staff.selectedEmployee ? Immutable.asMutable(staff.selectedEmployee) : {},
    isFetching: staff.isFetching,
    error: staff.error ? Immutable.asMutable(staff.error) : {},
    alert: staff.alert
});

export default connect(mapStateToProps)(EmployeeDetailComponent);
