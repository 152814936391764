import React from 'react';
import {Field, change, reduxForm} from 'redux-form';

class UpdateClientProfileForm extends React.Component{

    componentDidMount(){
        const {dispatch, accessToken, client} = this.props;
        dispatch(change("updateClientProfileForm", "access_token",accessToken));
        dispatch(change("updateClientProfileForm", "email_address",client.email));
        dispatch(change("updateClientProfileForm", "phone_number",client.phoneNumber));
        dispatch(change("updateClientProfileForm", "client_id",client.id));
    }

    render(){
        const {handleSubmit, pristine, submitting} = this.props;

        return (
            <form onSubmit={handleSubmit} style={{display:"flex", alignItems:"center",flexDirection:"column"}}>
                <Field component="input" className="form-input" type="text" name="email_address" placeholder="Email Address"/>
                <Field component="input" className="form-input" type="text" name="phone_number" placeholder="Phone Number"/>
                <Field component="input" type="hidden" name="access_token" style={{display:"none"}}/>
                <Field component="input" type="hidden" name="client_id" style={{display:"none"}}/>
                <button className="form-button button_link" disabled={pristine||submitting}>
                    Save Changes
                </button>
            </form>
        )
    }
}



UpdateClientProfileForm = reduxForm({
    form:"updateClientProfileForm"
})(UpdateClientProfileForm);

export default UpdateClientProfileForm;