import { STAFF_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    isFetching:false,
    allEmployees:[],
    selectedEmployee:{},
    error:{},
    alert:""
});

export default function staffReducer(state=initialState, action){
    switch (action.type){
        case STAFF_ACTION_TYPES.GET_STAFF:
        case STAFF_ACTION_TYPES.GET_STAFF_BY_ID:
        case STAFF_ACTION_TYPES.EDIT_EMPLOYEE:
        case STAFF_ACTION_TYPES.REMOVE_EMPLOYEE:
            return Immutable(state).merge({
                isFetching:true
            });
        case STAFF_ACTION_TYPES.GET_STAFF_SUCCESS:
            return Immutable(state).merge({
                isFetching:false,
                allEmployees:[...action.data],
                error:{}
            },{deep:true});
        case STAFF_ACTION_TYPES.GET_STAFF_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error:action.data
            });
        case STAFF_ACTION_TYPES.GET_STAFF_BY_ID_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                selectedEmployee:action.data,
                error:{}
            });
        case STAFF_ACTION_TYPES.GET_STAFF_BY_ID_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        case STAFF_ACTION_TYPES.EDIT_EMPLOYEE_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                alert:action.data,
                error:{}
            });
        case STAFF_ACTION_TYPES.EDIT_EMPLOYEE_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            },{deep:true});
        case STAFF_ACTION_TYPES.REMOVE_EMPLOYEE_SUCCESS:
            const selectedEmployee = {...state.selectedEmployee, isDeleted: true};
            return Immutable(state).merge({
                isFetching: false,
                alert: action.data,
                selectedEmployee: selectedEmployee,
                error:{}
            },{deep:true});
        case STAFF_ACTION_TYPES.REMOVE_EMPLOYEE_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        default:
            return state;
    }
}
