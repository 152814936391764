import React from 'react';
import {Link, Route} from 'react-router-dom';

const SideBarMenuItem = (props) => (
    <Route path={props.to} exact children={({match}) => (
        <li className={`dashboard__menu ${match ? "menu-active":""}`}>
            <i className={props.icon}/>
            <Link to={props.to}>{props.title}</Link>
        </li>
    )}/>
);

export default (SideBarMenuItem)