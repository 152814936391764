import React, { Component } from 'react';
import numberFormatter from 'number-formatter';
import moment from 'moment';
import Paginator from "./Paginator";
import PropTypes from 'prop-types';
import LoadingScreen from '../LoadingScreen';
import clientServices from '../../services/client';
import {getActiveStatus} from '../lists/EmployeeListComponent';

class DataTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      tableData:[],
      filter:"",
      pagination: null
    };
  };

  componentDidMount(){
    this.getTableData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.dataSource !== prevProps.dataSource) {
        this.getTableData();
    }
  }

  getTableData = (page=1, filter="",limit=10) => {
    const { dataSource, tableData } = this.props;
    const { accessToken, clientId, ...queryProps } = dataSource;
    const queryData = { limit, page, q: filter, ...queryProps };

    if(dataSource) {
      this.setLoading(true);

      clientServices.getStaff(accessToken, clientId, queryData)
        .then((response) =>
          this.setState({
            isLoading:false,
            tableData: response.data,
            filter,
            pagination: response.pagination
          }))
        .catch((error) => {
          this.setLoading(false);
        });
    } else {
        this.setState({
            tableData
        })
    }
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  filterList = (query) => {
    if(query.length > 3){
      this.getTableData(1, query);
    }
  };

  clearFilter = () => {
    this.getTableData();
  };

  formatDataBasedOnType = (data,type="string") => {
    switch (type){
      case 'string':
        return data;
      case 'number':
        return numberFormatter("#,###.00", Number(data));
      case 'date':
        return moment(data).format("ddd, MMM Do YYYY");
      default:
        return data;
    }
  };

  render() {
    const { tableColumnData, showTableDetail } = this.props;
    const { tableData, isLoading, filter, pagination  } = this.state;

    if (isLoading) return (
        <div className="dashboard-card" style={{minHeight:"20rem", position:"relative"}}>
            <LoadingScreen/>
        </div>
    )

    return (
        <div className="dashboard-table-overflow">
            {
              (tableData.length === 0) ? (
                <div className="dashboard-card" style={{width:"20%", margin:"20px auto", fontSize: "20px", color: "grey"}}>
                    No result found
                </div>
              ) : (
                <div>
                    <table className="dashboard__table">
                        <thead>
                            <tr>
                            {
                                tableColumnData.map((columnData) => (
                                <th key={columnData.key || columnData.alias} className="dashboard-table-item dashboard-table-item__header">
                                    {columnData.alias ? columnData.alias : columnData.key.replace(/_/g, " ")}
                                </th>
                                ))
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                            tableData.map((data, index)=>(
                                <tr
                                key={`${data.id}-${index}`}
                                className={getActiveStatus(data.active_status_id)}
                                onClick={() => showTableDetail && showTableDetail(data.id)}
                                >
                                { 
                                    tableColumnData.map((colData, index) => (
                                    <td key={index+1} className="dashboard-table-item dashboard-table-item__body">
                                        {
                                        colData.format ? 
                                            colData.format(data)
                                            : 
                                            (
                                            !data[colData.key] ?
                                                "N/A"
                                                :
                                                this.formatDataBasedOnType(data[colData.key || colData],colData.type)
                                            )
                                        }
                                    </td>
                                    ))
                                }
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                    {
                    pagination &&
                        <Paginator
                        lastPage={pagination.last_page}
                        currentPage={pagination.current_page}
                        onPageChange={(page) => {
                            this.getTableData(page, filter || "");
                        }}/>
                    }
              </div>
            )}
        </div>
    );
  }
}

DataTable.propTypes = {
  tableData: PropTypes.array,
  tableColumnData: PropTypes.arrayOf(PropTypes.shape({
    key:PropTypes.string.isRequired,
    type:PropTypes.string.isRequired,
    alias:PropTypes.string,
    format:PropTypes.func
  })),
  showTableDetail: PropTypes.func,
  styles: PropTypes.object,
  styleClass: PropTypes.string,
  dataSource: PropTypes.shape({
    // url:PropTypes.string,
    clientId: PropTypes.number,
    accessToken:PropTypes.string
  })
};

export default DataTable;