import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { logger } from '../middlewares/logger';
import rootReducer from '../reducers/rootReducer';

export const configureStore = (preloadedState) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    if( (!window.location.href.includes('testing')
        && !window.location.href.includes('localhost'))){
        return createStore(rootReducer, preloadedState, compose(
            applyMiddleware(
                thunkMiddleware
            )
        ));
    }else{
        return createStore(rootReducer, preloadedState, composeEnhancers(
            applyMiddleware(
                thunkMiddleware,
                logger
            )
        ));
    }
};