import React from 'react';
import {Switch, withRouter} from 'react-router-dom';
import InvoiceListComponent from './lists/InvoiceListComponent';
import InvoiceDetailsComponent from './InvoiceDetailsComponent';
import AddEmployeeComponent from './AddEmployeeComponent';
import NewInvoiceComponent from "./NewInvoiceComponent";
import PropsRoute from './utils/PropsRoute';

const InvoiceComponent = ({match, user}) => (
    <Switch>
        <PropsRoute exact path={`${match.url}/:invoiceId`} component={InvoiceDetailsComponent} user={user}/>
        <PropsRoute exact path={`${match.url}/edit/add-staff`} component={AddEmployeeComponent} user={user}/>
        <PropsRoute exact path={`${match.url}/edit/new-invoice`} component={NewInvoiceComponent} user={user}/>
        <PropsRoute path={match.url} component={InvoiceListComponent} user={user}/>
    </Switch>
);


export default withRouter(InvoiceComponent);