import React from 'react';
import {connect} from 'react-redux';
import {DISPLAY_ACTION_TYPES} from '../../actions/actionTypes';

class PageHeader extends React.Component{

    toggleSideBar = () => {
        const {dispatch} = this.props;
        dispatch({type:DISPLAY_ACTION_TYPES.TOGGLE_SIDEBAR});
    };

    render(){
        const {title} = this.props;

        return (
            <div className="top-right content">
                <div className="header">
                    <div className="header__left">
                        <button className="toggle-nav" onClick={this.toggleSideBar}>&#9776;</button>
                        <div className="header__title">
                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div className="header__right">
                        {
                            this.props.showButton &&
                            <div>
                                <button onClick={this.props.onButtonClick} style={{cursor: 'pointer'}}>{this.props.buttonTitle}</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


export default connect()(PageHeader)