import React from 'react';
import clientService from '../../services/client';
import {Field, change, reduxForm} from 'redux-form';
import { features } from '../../services/daara-config.json';
import LoadingScreen from '../LoadingScreen';

class AddMultipleEmployeeForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isChecking:false,
            canAddEmployees: false,
            masterListUrl: "",
            errorMessage:""
        }
    }

    componentDidMount(){

        const {dispatch, accessToken, refCode, clientId, subscriptionId} = this.props;

        dispatch(change("addMultipleEmployeeForm", "access_token",accessToken));
        dispatch(change("addMultipleEmployeeForm", "ref_code",refCode));
        dispatch(change("addMultipleEmployeeForm", "client_id",clientId));

        this.setState({
            isChecking: true
        });

        clientService.getInvoicePlanMasterList(refCode, accessToken, clientId, subscriptionId)
            .then((data)=>{
                this.setState({
                    isChecking: false,
                    canAddEmployees: true,
                    masterListUrl: data.url
                })
            }, (err)=>{
                this.setState({
                    isChecking: false,
                    canAddEmployees: false,
                    masterListUrl: "",
                    errorMessage: features.clientService.addMultipleEmployees ? err.response.detail : err.response.data.message
                })
            })

    }

    render(){
        const {handleSubmit, pristine, submitting } = this.props;

        const { isChecking, canAddEmployees, masterListUrl, errorMessage } = this.state;

        if(isChecking) return <LoadingScreen/>;

        if(!isChecking && !canAddEmployees)
            return (
                <div style={{display:"flex", justifyContent:"center", flexDirection:"column", height:"400px"}}>
                    <div style={{ padding: "2rem",background: "red", color: "white",fontSize: "1.3em"}}>
                        {errorMessage}
                    </div>
                </div>
            );

        if(!isChecking && canAddEmployees)
        return (
            <form onSubmit={handleSubmit}>
                <h2>Adding Multiple Employees:</h2>
                <p>&nbsp;</p>
                <h4>To add multiple employees at once, please click the button below to download a formatted Excel Spreadsheet, where you will fill in the details of the Employees you wish to add.</h4>
                <p>&nbsp;</p>
                <a
                    href={masterListUrl}
                    className="form-button content"
                    style={{display:"block", textDecoration:"none", textAlign:"center"}}>
                    Download SpreadSheet
                </a>
                <p>&nbsp;</p>
                <h4>When you are done filling up the SpreadSheet, click the button below to select the file, then upload the file</h4>
                <p>&nbsp;</p>
                <h4>
                    Select Spreadsheet:
                    <Field component={CustomFileInput} name="file" type="file" />
                </h4>
                <Field component="input" name="access_token" type="hidden" style={{display:"none"}}/>
                <Field component="input" name="client_id" type="hidden" style={{display:"none"}}/>
                <Field component="input" name="ref_code" type="hidden" style={{display:"none"}}/>
                <p>&nbsp;</p>
                <button
                    className="form-button" style={{backgroundColor:"#2eaf31"}}
                    type="submit"
                    disabled={pristine || submitting}>
                    Upload Completed SpreadSheet
                </button>
            </form>
        );
    }
}

AddMultipleEmployeeForm = reduxForm({
    form:"addMultipleEmployeeForm"
})(AddMultipleEmployeeForm);

const CustomFileInput = (field) => {
    delete field.input.value; // <-- just delete the value property
    return <input type="file" id="file" {...field.input} />;
};

export default AddMultipleEmployeeForm;