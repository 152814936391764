import React from 'react';
import LoadingScreen from './LoadingScreen';
import clientService from '../services/client';


class PreviousCards extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            cards: [],
            isLoading: true,
            error: ""
        }
    }

    componentDidMount() {
        this.getCards()
    }

    getCards() {
        const { clientId, accessToken } = this.props
        clientService.getClientCards(clientId, accessToken)
        .then(data=>{
            this.setState({
                cards: data.filter(card => card.is_reusable === 1),
                isLoading: false
            });
        }, e => {
            this.setState({
                isLoading: false,
                error: {
                    message: e.response.data.message
                }
            })
        });
    }

    removeCard = (cardId) => {
        const { clientId, accessToken } = this.props
        this.setState({isLoading: true})
        clientService.removeClientCard(clientId, accessToken, cardId)
            .then(() => {
                this.getCards()
            })
            .catch(e => {
                this.setState({
                    isLoading: false,
                    error: {
                        message: e.response.data.message
                    }
                })
            })
    }

    renderLoading(){
        return (
            <div style={{width: "35em", position: "relative", minHeight: "7em"}}>
                <LoadingScreen/>
            </div>
        )
    };

    render(){

        const { cards, isLoading, error } = this.state;

        if (isLoading) return this.renderLoading();

        return (
            error ? <div style={{width: "35em"}}>
                        <p style={{fontSize: "1.6em", textAlign: "center", color: "#9c9c9c"}}>{error.message}</p>
                </div> :
                cards.length > 0 ? 
                    ( <ul style={{width: "35em"}}>
                        {cards.map(card => (
                            <li key={card.id} 
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    height: "3em",
                                    fontSize: "1.6em",
                                    borderTop: "1px solid #e3e3e3"
                                }}>
                                <p>{card.card_description}</p>
                                <i style={{cursor: "pointer"}} className="fa fa-trash" onClick={() => this.removeCard(card.id)}/>
                            </li>
                        ))}
                    </ul> ) : ( 
                        <div style={{width: "35em"}}>
                            <p style={{fontSize: "1.6em", textAlign: "center", color: "#9c9c9c"}}>No previous card found</p>
                        </div>
                    )
        );
    }
}


export default PreviousCards;