import React from 'react';
// import queryString from 'querystring';
import { sessionService } from 'redux-react-session';
import { withRouter, Redirect } from 'react-router-dom';
import {mapClient} from '../services/client';
import clientService from '../services/client';
import LoadingScreen from './LoadingScreen';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import MainContent from './boxes/MainContent';

class AccountSelectionComponent extends React.Component {
    //if user not found, go to login
    //show list of clientAdmin accounts
    //on selection of an account, get account details
    //on getting account deets, switch companyInfo in session with derived account deets, set selectedClient in user prefs
    //navigate to dashboard

    constructor(props){
        super(props);
        // const {location} = props;
        // const accessToken = queryString.parse(location.search.substr(1)).at;
        this.state = {
            clients: this.props.location.state.accounts,
            isLoading: false,
            selectedClient:false,
            accessToken:this.props.location.state.accessToken
        }
    }

    renderLoading(){
        return <MainContent>
            <LoadingScreen/>
        </MainContent>
    }

    getClientAccount = (id) =>{
        this.setState({
            isLoading: true
        });
        const {accessToken} = this.state;
        clientService.getClientAdminAccountById(accessToken,id)
            .then((account)=>{
                this.switchClients(account);
            },e=>console.log(e));
    };

    switchClients = (newClient) => {
        sessionService.loadUser()
            .then((client)=>{                
                client.companyInfo = mapClient([newClient]).companyInfo; //setting companyInfo of user to reordered json's info
                client.preferences.selectedClient = newClient.id;
                sessionService.saveUser(client)
                    .then(()=>{
                        this.setState({
                            isLoading: false,
                            selectedClient:true
                        })
                    })
            });
    };


    render(){
        const {accessToken, isLoading, clients, selectedClient} = this.state;

        if(!accessToken) return (<Redirect to="/login"/>);

        if(isLoading) return this.renderLoading();

        if(selectedClient) return (<Redirect to="/dashboard"/>);//redirect back to dashboard

        if(clients)
            return (
                <MainContent>
                    <PageHeader title="RelianceHMO Client Dashboard"/>
                    <PageBody>
                        <div className="client-account-list-wrapper">
                            <h2>Please select a client:</h2>
                            <ul className="client-account-list">
                                {clients.map(client=>(
                                    <li key={client.id}>
                                        <div className="client-account-list-item" onClick={()=>this.switchClients(client)}>
                                            <img src={client.logo} alt={client.name}/>
                                            <div className="info">
                                                <div className="title">{client.name}</div>
                                                <div className="address">{client.address}</div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </PageBody>
                </MainContent>
            )



    }

}

export default withRouter(AccountSelectionComponent);