import { combineReducers } from 'redux';
import staffReducer from './staff';
import invoiceReducer from './invoice';
import clientReducer from './client';
import displayReducer from './display';
import {reducer as formReducer} from 'redux-form';
import { sessionReducer } from 'redux-react-session';

const rootReducer = combineReducers({
    staff:staffReducer,
    invoice:invoiceReducer,
    client:clientReducer,
    form:formReducer,
    session:sessionReducer,
    display:displayReducer
});

export default rootReducer;
