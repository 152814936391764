import React from 'react';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import clientServices from '../../services/client';
import { withRouter, Link } from 'react-router-dom';
import LoadingScreen from '../LoadingScreen';
import PageHeader from '../boxes/PageHeader';
import Modal from '../boxes/Modal';
import PageBody from '../boxes/PageBody';
import MainContent from '../boxes/MainContent';
import DataTable from '../boxes/DataTable';
import EnrolleeFilter from '../boxes/EnrolleeFilter';
import { features } from '../../services/daara-config.json';
class EmployeeListComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewEnrollee: false,
            invoiceList: [],
            showInvoiceList: false,
            selectedStatus: '',
            searchInput: '',
            search: null
        };
    }

    componentDidMount() {
        const { user } = this.props;
        const clientId = features.invoiceService.list
            ? user.companyInfo.clientId
            : user.companyInfo.id;
        clientServices.getInvoices(user.credentials.accessToken, clientId).then((response) => {
            this.setState({
                invoiceList: response
            });
        });
    }

    renderLoading() {
        return (
            <MainContent>
                <PageHeader title="" />
                <PageBody>
                    <LoadingScreen />
                </PageBody>
            </MainContent>
        );
    }

    searchEnrollees = (searchTerm) => {
        this.setState({
            search: searchTerm
        });
    };

    toggleShowInvoiceList = () => {
        this.setState({
            showInvoiceList: !this.state.showInvoiceList
        });
    };

    selectStatus = (e) => {
        this.setState({ selectedStatus: e.target.value });
    };

    render() {
        const { user, location } = this.props;
        const invoiceRefCode = location.state ? location.state.invoiceRefCode : '';

        return (
            <MainContent>
                <PageHeader
                    title="Employees"
                    showButton
                    buttonTitle="Add Employee"
                    onButtonClick={this.toggleShowInvoiceList}
                />
                <PageBody>
                    {this.state.showInvoiceList && (
                        <InvoiceListModal
                            invoices={this.state.invoiceList}
                            canModify={user.companyInfo.canModify}
                            onClose={this.toggleShowInvoiceList}
                        />
                    )}
                    <EnrolleeFilter
                        status={this.state.selectedStatus}
                        selectStatus={this.selectStatus}
                        searchEnrollees={this.searchEnrollees}
                    />
                    <DataTable
                        dataSource={{
                            clientId: features.invoiceService.list
                                ? user.companyInfo.clientId
                                : user.companyInfo.id,
                            accessToken: user.credentials.accessToken,
                            invoice_ref_code: invoiceRefCode,
                            active_status_id: this.state.selectedStatus,
                            s: this.state.search
                        }}
                        tableColumnData={
                            features.enrolleeService.list
                                ? daaraEmployeeColumnData
                                : employeeColumnData
                        }
                    />
                </PageBody>
            </MainContent>
        );
    }
}

const daaraEmployeeColumnData = [
    {
        key: 'firstName',
        type: 'string',
        alias: 'Employee Name',
        format: (data) =>
            data.status.toLowerCase() === 'active' ? (
                <Link
                    to={`/dashboard/staff/${data.id}`}
                >{`${data.firstName}  ${data.lastName}`}</Link>
            ) : (
                <p>{`${data.firstName}  ${data.lastName}`}</p>
            )
    },
    {
        key: 'email',
        type: 'string',
        alias: 'Email'
    },
    {
        key: 'product',
        type: 'string',
        alias: 'Plan'
    },
    {
        key: 'status',
        type: 'string',
        alias: 'Status',
        format: (data) => data.status.toLowerCase()
    },
    {
        key: 'hasProfile',
        type: 'string',
        alias: 'Profile',
        format: (data) => (data.hasProfile ? 'complete' : 'incomplete')
    }
];

const employeeColumnData = [
    {
        key: 'first_name',
        type: 'string',
        alias: 'Employee Name',
        format: (data) =>
            getActiveStatus(data.active_status_id) === 'active' ? (
                <Link
                    to={`/dashboard/staff/${data.id}`}
                >{`${data.first_name}  ${data.last_name}`}</Link>
            ) : (
                <p>{`${data.first_name}  ${data.last_name}`}</p>
            )
    },
    {
        key: 'email_address',
        type: 'string',
        alias: 'Email'
    },
    {
        key: 'product_name',
        type: 'string',
        alias: 'Plan'
    },
    {
        key: 'active_status_id',
        type: 'string',
        alias: 'Status',
        format: (data) => getActiveStatus(data.active_status_id)
    },
    {
        key: 'has_profile',
        type: 'string',
        alias: 'Profile',
        format: (data) => (data.has_profile ? 'complete' : 'incomplete')
    }
];

const InvoiceListModal = ({ invoices, canModify, onClose }) => {
    return (
        <Modal title="Please select an Invoice" onClose={onClose}>
            {features.invoiceService.list ? (
                <DaaraInvoiceList invoices={invoices} canModify={canModify} />
            ) : (
                <InvoiceList invoices={invoices} canModify={canModify} />
            )}
        </Modal>
    );
};

const DaaraInvoiceList = ({ invoices, canModify }) => {
    const validInvoices = invoices.filter((invoice) => invoice.availableSlot > 0);

    return validInvoices.length > 0 ? (
        <table className="dashboard__table">
            <thead>
                <tr>
                    <th>Invoice Code</th>
                    <th>Description</th>
                    <th>Amount (NGN)</th>
                    <th>Date Issued</th>
                </tr>
            </thead>
            <tbody>
                {validInvoices.map((invoice) => (
                    <tr key={invoice.referenceCode}>
                        <td>
                            {invoice.availableSlot > 0 ? (
                                <Link
                                    to={`/dashboard/invoices/edit/add-staff?refCode=${invoice.referenceCode}`}
                                >
                                    {invoice.referenceCode}
                                </Link>
                            ) : (
                                invoice.referenceCode
                            )}
                        </td>
                        <td>{invoice.description}</td>
                        <td>{numberFormatter('#,###.00', invoice.price)}</td>
                        <td>{moment(invoice.createdDate).format('dddd, MMMM Do YYYY')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : canModify ? (
        <div style={{ maxWidth: '500px', textAlign: 'center' }}>
            <p style={{ fontSize: '1.6rem', lineHeight: '2.4rem' }}>
                You have used up all the available employee slots in your invoices. Click on the
                button below to purchase new plans for your employees.
            </p>
            <Link to="/dashboard/invoices/edit/new-invoice">
                <button className="primary_button">BUY NEW PLANS</button>
            </Link>
        </div>
    ) : (
        <p style={{ fontSize: '1.6rem', maxWidth: '500px', lineHeight: '2.4rem' }}>
            You have used up all the available employee slots in your invoice. To add more
            employee's, please contact support at{' '}
            <a href="mailto:hello@reliancehmo.com?subject=New Invoice">hello@reliancehmo.com</a>, so
            a new invoice can be generated for you. Thank You.
        </p>
    );
};

const InvoiceList = ({ invoices, canModify }) => {
    const validInvoices = invoices.filter((invoice) => invoice.has_enrollees_left);

    return validInvoices.length > 0 ? (
        <table className="dashboard__table">
            <thead>
                <tr>
                    <th>Invoice Code</th>
                    <th>Description</th>
                    <th>Amount (NGN)</th>
                    <th>Date Issued</th>
                </tr>
            </thead>
            <tbody>
                {validInvoices.map((invoice) => (
                    <tr key={invoice.reference_code}>
                        <td>
                            {invoice.has_enrollees_left ? (
                                <Link
                                    to={`/dashboard/invoices/edit/add-staff?refCode=${invoice.reference_code}`}
                                >
                                    {invoice.reference_code}
                                </Link>
                            ) : (
                                invoice.reference_code
                            )}
                        </td>
                        <td>{invoice.description}</td>
                        <td>{numberFormatter('#,###.00', invoice.total_price)}</td>
                        <td>{moment(invoice.issue_time).format('dddd, MMMM Do YYYY')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : canModify ? (
        <div style={{ maxWidth: '500px', textAlign: 'center' }}>
            <p style={{ fontSize: '1.6rem', lineHeight: '2.4rem' }}>
                You have used up all the available employee slots in your invoices. Click on the
                button below to purchase new plans for your employees.
            </p>
            <Link to="/dashboard/invoices/edit/new-invoice">
                <button className="primary_button">BUY NEW PLANS</button>
            </Link>
        </div>
    ) : (
        <p style={{ fontSize: '1.6rem', maxWidth: '500px', lineHeight: '2.4rem' }}>
            You have used up all the available employee slots in your invoice. To add more
            employee's, please contact support at{' '}
            <a href="mailto:hello@reliancehmo.com?subject=New Invoice">hello@reliancehmo.com</a>, so
            a new invoice can be generated for you. Thank You.
        </p>
    );
};

export function getActiveStatus(id) {
    return ['active', 'pending', 'deleted', 'suspended', 'expired', 'deactivated', 'delayed'][
        id - 1
    ];
}

export default withRouter(EmployeeListComponent);
