import React from 'react';
import * as _ from 'lodash';
import clientService from '../services/client';
import querystring from 'querystring';
import NotificationBanner from './boxes/NotificationBanner';
import AddSingleEmployeeForm from './forms/AddSingleEmployeeForm';
import AddMultipleEmployeeForm from './forms/AddMultipleEmployeeForm';
import LoadingScreen from './LoadingScreen';
import PageNotFound from './PageNotFound';
import MainContent from './boxes/MainContent';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import { features } from '../services/daara-config.json';
class AddEmployeeComponent extends React.Component {
    constructor(props) {
        super(props);
        const refCode = querystring.parse(this.props.location.search.substr(1)).refCode;
        this.state = {
            isLoading: false,
            invoiceRefCode: refCode,
            invoicePlans: [],
            error: {},
            alert: '',
            subscriptionId: null
        };
    }

    componentDidMount() {
        this.getPlans();

        if (features.clientService.downloadEmployeeList && this.state.subscriptionId == null) {
            const { invoiceRefCode } = this.state;
            clientService.getClientSubscriptions(invoiceRefCode, this.props.user.companyInfo.id).then(data => {
                this.setState({
                    subscriptionId: data.id
                });
            });
        }
    }

    getPlans = () => {
        const { invoiceRefCode } = this.state;
        const { accessToken } = this.props.user.credentials;

        this.setLoading(true);

        clientService
            .getInvoiceByReferenceCode(invoiceRefCode, accessToken, this.props.user.companyInfo.id)
            .then(
                (data) => {
                    if (features.invoiceService.list) {
                        this.setState({
                            isLoading: false,
                            invoicePlans: data.invoiceItems,
                            invoiceId: data.id
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            invoicePlans: data.products,
                            invoiceId: data.id
                        });
                    }
                },
                (err) => {
                    this.setState({
                        error: {
                            message: err.message
                        },
                        alert: ''
                    });
                }
            );
    };

    renderInvoiceNotFound() {
        return (
            <MainContent>
                <PageNotFound />
            </MainContent>
        );
    }

    renderLoading() {
        return (
            <MainContent>
                <LoadingScreen />
            </MainContent>
        );
    }

    onSingleFormSubmit = (values) => {
        this.setLoading(true);

        clientService
            .addSingleEmployee(
                values.first_name,
                values.last_name,
                values.email_address,
                values.phone_number,
                values.product_id,
                values.no_of_dependant,
                values.access_token,
                values.client_id,
                this.state.invoiceRefCode
            )
            .then(
                (response) => {
                    const { name, enrollees_left, message } = response;
                    const msg = `${message} to ${name} plan. You have ${enrollees_left} slots left on this plan.`;
                    this.getPlans();
                    this.setNotification(msg, 'success');
                },
                (err) => {
                    this.setLoading(false);
                    this.setNotification(err.response.data.message, 'error');
                }
            )
            .catch(() => {
                this.setLoading(false);
                this.setNotification(
                    'Oops! An error seems to have occurred. Please try again, or send a mail to hello@reliancehmo.com for assistance.',
                    'error'
                );
            });
    };

    onMultipleFormSubmit = (values) => {
        const { access_token, ref_code, client_id } = values;

        this.setLoading(true);

        const formData = new FormData();
        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === 'file') formData.append(key, values[key][0]); //for selecting the first file in the fileList
                if (['access_token', 'ref_code', 'client_id'].includes(key)) continue;
                formData.append(key, values[key]);
            }
        }

        const { subscriptionId } = this.state;
        clientService
            .addMultipleEmployees(formData, access_token, client_id, ref_code, subscriptionId)
            .then(
                (response) => {
                    const { message } = response;
                    const msg = `${message}`;
                    this.setLoading(false);
                    this.setNotification(msg, 'success');
                },
                (err) => {
                    this.setLoading(false);
                    this.setNotification(err.response.data.message, 'error');
                }
            )
            .catch(() => {
                this.setLoading(false);
                this.setNotification(
                    'Oops! An error seems to have occurred. Please try again, or send a mail to hello@reliancehmo.com for assistance.',
                    'error'
                );
            });
    };

    setLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    };

    setNotification = (message, type) => {
        if (type === 'success') {
            this.setState({
                alert: message,
                error: null
            });
        } else {
            this.setState({
                error: {
                    message: message
                },
                alert: ''
            });
        }
    };

    render() {
        const { invoiceRefCode, invoicePlans, isLoading, error, alert, subscriptionId } = this.state;

        const { user } = this.props;

        if (isLoading) return this.renderLoading();

        if (_.isEmpty(invoicePlans)) return this.renderInvoiceNotFound();

        return (
            <MainContent>
                <PageHeader title="Add Employee(s)" />
                <PageBody>
                    {(!_.isEmpty(error) || alert) && (
                        <NotificationBanner
                            title={error ? 'Error!' : 'Notification!'}
                            message={error ? error.message : alert}
                            type={error ? 'error' : 'success'}
                        />
                    )}
                    <div className="addEmployee">
                        <h2>
                            You are about to add one or more employees to Invoice #{invoiceRefCode}
                        </h2>
                        <p>Please follow the instructions below to complete the process</p>
                        <div className="l-addEmployee">
                            <div className="addEmpForm">
                                <AddSingleEmployeeForm
                                    invoicePlans={invoicePlans}
                                    onSubmit={this.onSingleFormSubmit}
                                />
                            </div>
                            <div id="or">
                                <p>&nbsp;OR&nbsp;</p>
                            </div>
                            <div className="spreadSheet">
                                <AddMultipleEmployeeForm
                                    refCode={invoiceRefCode}
                                    accessToken={user.credentials.accessToken}
                                    clientId={user.companyInfo.id}
                                    onSubmit={this.onMultipleFormSubmit}
                                    subscriptionId={subscriptionId}
                                />
                            </div>
                        </div>
                        {features.invoiceService.list ? (
                            <div className="addEmployee__invsum">
                                <h2>Invoice Plan Summary:</h2>
                                <table className="dashboard__table">
                                    <thead>
                                        <tr>
                                            <th>Plan Description</th>
                                            <th>Total</th>
                                            <th>Added</th>
                                            <th>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoicePlans.map((plan) => (
                                            <tr key={plan.id}>
                                                <td>{plan.name}</td>
                                                <td>{plan.quantity}</td>
                                                <td>{plan.allocatedSlots}</td>
                                                <td>{plan.quantity - plan.allocatedSlots}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="addEmployee__invsum">
                                <h2>Invoice Plan Summary:</h2>
                                <table className="dashboard__table">
                                    <thead>
                                        <tr>
                                            <th>Plan Description</th>
                                            <th>Total</th>
                                            <th>Added</th>
                                            <th>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoicePlans.map((plan) => (
                                            <tr key={plan.id}>
                                                <td>{plan.name}</td>
                                                <td>{plan.no_of_enrollees}</td>
                                                <td>
                                                    {plan.no_of_enrollees - plan.enrollees_left}
                                                </td>
                                                <td>{plan.enrollees_left}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </PageBody>
            </MainContent>
        );
    }
}

export default AddEmployeeComponent;
