import React from 'react';
import { BounceLoader } from 'react-spinners';
import '../index.css';

const LoadingScreen = () => {
    return(
        <div style={{margin:"0",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
            <BounceLoader color={'#107BC0'}/>
        </div>
    )
};

export default LoadingScreen