
const defaultStore = {
    client:{
        isFetching:false,
        isLoggedIn: false,
        invalidated:false,
        alert:"",
        error:{},
        overViewData:{},
        usageData:{
            isFetching:false,
            data:[],
            summary:{
                totalQuestionsAsked:0,
                totalTipsRead:0,
                totalVisits:0
            }
        }
    },
    invoice:{
        isFetching:false,
        invoices:[],
        selectedInvoice:{},
        error:{},
        alert:""
    },
    staff:{
        isFetching:false,
        allEmployees:[],
        selectedEmployee:{},
        error:{},
        alert:""
    },
    display:{
        showSideBar:false
    }
};

export default defaultStore;