import axios from 'axios';
import querystring from 'querystring';
import * as _ from 'lodash';
import {
    DAARA_CLIENT_SERVICE_URL,
    DAARA_ENROLLEE_SERVICE_URL,
    DAARA_INVOICE_SERVICE_URL,
    DAARA_PRODUCT_SERVICE_URL,
    DAARA_FILE_SERVICE_URL,
    DAARA_TOKEN_NAME,
    DOMAIN_COOKIE_NAME,
    getCookie,
    getDaaraActiveStatus
} from './utilityService';
import { features } from './daara-config.json';
import { sendHttpGetRequest, sendHttpPutRequest, sendHttpPostRequest, sendHttpPatchRequest, sendHttpDeleteRequest} from './axiosInterceptor';
export const getAPIbaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
        case 'v3test':
        case 'development':
            return 'https://api.develop.reliancehmo.com/v3';
        case 'daara':
            return 'https://api.daara.develop.reliancehmo.com/v3';
        case 'daara-staging':
            return 'https://api.daara.staging.reliancehmo.com/v3';
        case 'staging':
            return 'https://api.staging.reliancehmo.com/v3';
        default:
            return 'https://api.reliancehmo.com/v3';
    }
};

const BASE_URL_RELIANCE = getAPIbaseUrl();

export const getRedirectDomain = (redirectDomain) => {
    let domainParts = window.location.host.split('.');
    if (domainParts.length === 1) {
        return `${redirectDomain}.reliancehmo.com`;
    } else {
        domainParts[0] = redirectDomain;
        return domainParts.join('.');
    }
};

const getDomain = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
        case 'v3test':
        case 'development':
            return 'develop.reliancehmo.com';
        case 'daara':
            return 'daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'daara.staging.reliancehmo.com';
        case 'staging':
            return 'staging.reliancehmo.com';
        default:
            return 'reliancehmo.com';
    }
};

export const DOMAIN = getDomain();

class ClientService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL_RELIANCE
        });
        this.data = {};
    }

    auth() {
        let accessToken = getCookie(DOMAIN_COOKIE_NAME);
        if (features.clientService.auth) {
            return sendHttpGetRequest(`${DAARA_CLIENT_SERVICE_URL}/clients/auth`).then(
                (response) => {
                    let client = daaraMapClient(response.data);
                    return client;
                }
            );
        }
        return this.ax
            .post(`/auth/clients`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                let client = mapClient(response.data.data.accounts);
                client.credentials.accessToken = accessToken;
                return client;
            });
    }

    getOverViewData(accessToken, clientId) {
        this.data = querystring.stringify({
            access_token: accessToken
        });
        if (features.clientService.overview) {
            return sendHttpGetRequest(
                `${DAARA_CLIENT_SERVICE_URL}/clients/${clientId}/overview`
            ).then((response) => {
                const { totalAmount, numberOfEnrollees, numberOfProduct } = response.data;
                return {
                    amount_paid: totalAmount,
                    no_of_enrollees: numberOfEnrollees,
                    no_of_plans: numberOfProduct
                };
            });
        }
        return this.ax
            .get(`/clients/${clientId}/overview`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getUsageData(accessToken, numberOfDays, clientId) {
        if (features.clientService.usage) {
            return sendHttpGetRequest(`${DAARA_CLIENT_SERVICE_URL}/clients/${clientId}/usage?numberOfDays=${numberOfDays}`)
                    .then((response) => {
                        const { usageSingles, usageSummaryDTO } = response.data;
                        return {
                            results: daaraMapUsageSingle(usageSingles),
                            summary: daaraMapUsageSummary(usageSummaryDTO)
                        };
                    });
        }
        this.data = {
            num_of_days: numberOfDays
        };
        return this.ax
            .get(
                `/clients/${clientId}/usage`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                },
                this.data
            )
            .then((response) => {
                return response.data.data;
            });
    }

    getInvoices(accessToken, clientId) {
        if (features.invoiceService.list) {
            return sendHttpGetRequest(
                `${DAARA_INVOICE_SERVICE_URL}/clients/${clientId}/invoices`
            ).then((response) => response.data);
        }
        return this.ax
            .get(`/clients/${clientId}/invoices`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    changeLogo(logo) {
        const formData = new FormData();

        if (features.fileService.upload) {
            formData.append('files', logo);
            return sendHttpPostRequest(`${DAARA_FILE_SERVICE_URL}/upload-multiple/image`, formData)
                .then((response) => {
                    const { key } = response.data[0];
                    return key.substr(key.lastIndexOf('/') + 1);
                })
                .catch((e) => {
                    return {
                        message: e.response.data.message || 'An Error Occurred',
                        code: e.response.status
                    };
                });
        }

        formData.append('file_use', 'client_logo');
        formData.append('file', logo);
        return this.ax
            .post(`/upload`, formData, {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                return response.data.data.filename;
            });
    }

    updateProfile(email, phone, logo_filename, accessToken, client_id) {
        if (features.clientService.update) {
            this.data = {email, phoneNumber: phone, logo: logo_filename}
            return sendHttpPatchRequest(`${DAARA_CLIENT_SERVICE_URL}/clients/${client_id}/profile`, this.data)
                .then(() => "Client profile updated successfully!");
        }
        this.data = querystring.stringify({
            email_address: email,
            phone_number: phone,
            logo_filename
        });
        return this.ax
            .patch(`/clients/${client_id}/profile`, this.data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            });
    }

    getStaff(accessToken, clientId, queryData) {
        if (queryData.invoice_ref_code === '') delete queryData.invoice_ref_code;
        if (features.enrolleeService.list) {
            const query = {
                page: queryData.page == 1 ? 0 : queryData.page,
                query: queryData.s,
                size: queryData.limit,
                status: getDaaraActiveStatus(queryData.active_status_id)
            };
            return sendHttpGetRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client/${clientId}/enrollees?${querystring.stringify(
                    query
                )}`
            ).then((response) => {
                const pagination = {
                    last_page: response.data.totalPages,
                    current_page: queryData.page
                };
                return { data: daaraMapEnrollee(response.data.content), pagination };
            });
        }
        return this.ax
            .get(`/clients/${clientId}/enrollees?${querystring.stringify(queryData)}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    getInvoiceByReferenceCode(refCode, accessToken, clientId) {
        if (features.invoiceService.view) {
            return sendHttpGetRequest(
                `${DAARA_INVOICE_SERVICE_URL}/clients/${clientId}/invoices/${refCode}`
            ).then((response) => {
                return response.data;
            });
        }
        return this.ax
            .get(`/clients/${clientId}/invoices/${refCode}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getInvoicePlanMasterList(refCode, accessToken, clientId, subscriptionId) {
        if (features.clientService.downloadEmployeeList) {
            return sendHttpGetRequest(
                `${DAARA_INVOICE_SERVICE_URL}/invoices/download-master-list?clientSubscriptionId=${subscriptionId}`,{
                  headers: {
                    "Content-Disposition": "attachment; filename=template.xlsx",
                    "Content-Type":
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  },
                  responseType: "blob"
                }).then(function(response) {
                    const blob = response.data;
                    blob.lastModifiedDate = new Date();
                    blob.name = "employees_list.xlsx";
                    return {url: window.URL.createObjectURL(blob)};
                });
        }
        return this.ax
            .get(`/clients/${clientId}/invoices/${refCode}/list-download`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getClientSubscriptions(referenceCode, clientId) {
        return sendHttpGetRequest(
            `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/${clientId}/summary?invoices=${[referenceCode]}`
            ).then((response) => response.data[0]);
    }

    getAllPlans() {
        if (features.productService.load) {
            return sendHttpGetRequest(`${DAARA_PRODUCT_SERVICE_URL}/products/retails`).then(
                (res) => {
                    return res.data;
                }
            );
        }
        return this.ax.get(`/products`).then((response) => {
            return response.data.data;
        });
    }

    getClientCards(client_id, access_token) {
        if(features.clientService.cards) {
            return sendHttpGetRequest(`${DAARA_CLIENT_SERVICE_URL}/clients/${client_id}/cards`)
                    .then(response => {
                        return response.data.map(card => {
                            const {id, cardDescription, isReusable } = card;
                            return {id, card_description: cardDescription,is_reusable: isReusable === true ? 1 : 0};
                        });
                    });
        }
        
        return this.ax
            .get(`/clients/${client_id}/cards`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    removeClientCard(client_id, access_token, cardId) {
        if (features.clientService.cards) {
            return sendHttpDeleteRequest(`${DAARA_CLIENT_SERVICE_URL}/clients/${client_id}/cards/${cardId}`);
        }
        return this.ax
            .delete(`/clients/${client_id}/cards/${cardId}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    newInvoice(invoice, access_token) {
        invoice.callback_url = '/payment-successful';
        return this.ax
            .post(`/cart`, invoice, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    addSingleEmployee(
        firstName,
        lastName,
        email,
        phone,
        productId,
        numOfDependants,
        accessToken,
        clientId,
        invoiceRefCode
    ) {
        if (features.clientService.addEmployee) {
            this.data = {
                clientId,
                firstName, 
                lastName, 
                email, 
                phoneNumber: phone, 
                productId, 
                numberOfDependants: numOfDependants
            };
            return sendHttpPostRequest(
                `${DAARA_CLIENT_SERVICE_URL}/clients/${clientId}/invoices/${invoiceRefCode}/enrollees`, this.data
            ).then(response => {
                const {data: {productName, availableSlot}, message} = response.data;
                return { name: productName, enrollees_left: availableSlot, message }
            });
        }
        this.data = {
            first_name: firstName,
            last_name: lastName,
            email_address: email,
            phone_number: phone,
            product_id: productId,
            number_of_dependants: numOfDependants || 0
        };

        return this.ax
            .post(`/clients/${clientId}/invoices/${invoiceRefCode}/enrollees`, this.data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    addMultipleEmployees(formData, accessToken, clientId, refCode, subscriptionId) {
        if (features.clientService.addMultipleEmployees) {
            return sendHttpPostRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/upload-master-list?subscriptionId=${subscriptionId}&clientReference=${clientId}`,
                formData,
                {
                    headers: {
                        'Content-type': 'multipart/form-data'
                    }
                }).then(response => {
                    return { message : `Successfully added enrollee(s)`}
                });
        }
        return this.ax
            .post(`/clients/${clientId}/invoices/${refCode}/list-upload`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getClientAdminAccountById(accessToken, clientId) {
        return this.ax
            .get(`/clients/${clientId}/profile`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getStaffById(id, accessToken, clientId) {
        if (features.invoiceService.update) {
            return sendHttpGetRequest(`${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${id}`).then(
                (response) => {
                    return response.data;
                }
            );
        }
        return this.ax
            .get(`/clients/${clientId}/enrollees/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    editEmployee(userId, firstName, lastName, email, phone, accessToken, clientId) {
        this.data = querystring.stringify({
            first_name: firstName,
            last_name: lastName,
            email_address: email,
            phone_number: phone
        });

        return this.ax
            .patch(`/clients/${clientId}/enrollees/${userId}`, this.data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            });
    }

    removeEnrollee(userId, accessToken, clientId) {
        return this.ax
            .delete(`/clients/${clientId}/enrollees/${userId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            });
    }

    changePaymentFrequency(accessToken, clientId, invoiceRefCode, frequency) {
        if (features.invoiceService.update) {
            return sendHttpPutRequest(
                `${DAARA_INVOICE_SERVICE_URL}/invoices/${invoiceRefCode}/frequency?freq=${frequency}`
            ).then((response) => {
                return response.data.message;
            });
        }
        return this.ax
            .put(
                `/clients/${clientId}/invoices/${invoiceRefCode}?${querystring.stringify({
                    frequency
                })}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            });
    }
}

export function daaraMapEnrollee(data) {
    return data.map((enrollee) => ({
        id: enrollee.id,
        firstName: enrollee.person.firstName,
        lastName: enrollee.person.lastName,
        email: enrollee.person.email,
        product: enrollee.product.name,
        status: enrollee.status,
        hasProfile: enrollee.hasCompleteProfile
    }));
}

export function daaraMapClient(accounts) {
    return {
        companyInfo: {
            id: _.get(accounts[0], 'id'),
            clientId: _.get(accounts[0], 'clientId'),
            name: _.get(accounts[0], 'name'),
            email: _.get(accounts[0], 'email'),
            phoneNumber: _.get(accounts[0], 'phoneNumber'),
            address: _.get(accounts[0], 'address'),
            logoUrl: _.get(accounts[0], 'logo'),
            industry: _.get(accounts[0], 'industry'),
            canModify: _.get(accounts[0], 'canModify')
        },
        adminInfo: {
            position: _.get(accounts[0], 'position'),
            accounts: accounts
        },
        credentials: {
            accessToken: getCookie(`${DAARA_TOKEN_NAME}`)
        },
        preferences: {
            allowNotifications: '',
            selectedClient: accounts.length > 1 ? -1 : _.get(accounts[0], 'id') //if client admin has multiple accounts, set selectedClient ot -1 so that they select which client they want
        }
    };
}

export function mapClient(accounts) {
    return {
        companyInfo: {
            id: _.get(accounts[0], 'id'),
            name: _.get(accounts[0], 'name'),
            email: _.get(accounts[0], 'email_address'),
            phoneNumber: _.get(accounts[0], 'phone_number'),
            address: _.get(accounts[0], 'address'),
            logoUrl: _.get(accounts[0], 'logo'),
            industry: _.get(accounts[0], 'industry'),
            canModify: _.get(accounts[0], 'can_modify')
        },
        adminInfo: {
            position: _.get(accounts[0], 'position'),
            accounts: accounts
        },
        credentials: {
            accessToken: _.get(accounts[0], 'access_token'),
            daaraToken: _.get(accounts[0], 'daara_token'),
            refreshToken: _.get(accounts[0], 'daara_refresh_token')
        },
        preferences: {
            allowNotifications: '',
            selectedClient: accounts.length > 1 ? -1 : _.get(accounts[0], 'id') //if client admin has multiple accounts, set selectedClient ot -1 so that they select which client they want
        }
    };
}

function daaraMapUsageSingle(data) {
    return data.map((usage) => {
        const { date, hospitalVisits, questionAsked, tipsRead } = usage;
        return {
            date,
            questions_asked: questionAsked,
            tips_read: tipsRead,
            hospital_visits: hospitalVisits
        };
    });
}

function daaraMapUsageSummary(data) {
    const { totalHospitalVisits, totalQuestionAsked, totalTipsRead } = data;
    return {
        total_questions_asked: totalQuestionAsked,
        total_tips_read: totalTipsRead,
        total_hospital_visits: totalHospitalVisits
    };
}

export default new ClientService();
