import React from 'react';
import * as clientActions from '../actions/client';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import LoginForm from '../components/forms/LoginForm';
import LOGO from '../logo.svg';
import { getRedirectDomain } from '../services/client';
import { features } from '../services/daara-config.json';

class LoginContainer extends React.Component {
    componentDidMount() {
        const { dispatch } = this.props;
        if (features.clientService.auth) {
            return dispatch(clientActions.authClient('https://' + window.location.host));
        }
        dispatch(clientActions.authClient('http://' + window.location.host));
    }

    renderLoading = () => {
        return <LoadingScreen />;
    };

    onFormSubmit = (values, dispatch, props) => {
        dispatch(clientActions.loginClient(values.email, values.password, props.history));
    };

    render() {
        const { isFetching, checked, authenticated, error } = this.props;

        if (isFetching) return this.renderLoading();

        if (checked && authenticated) return <Redirect to="/dashboard" />;

        return (
            <div className="container">
                <div className="login_form">
                    <div className="form_logo">
                        <a href="/">
                            <img src={LOGO} alt="Reliance HMO's Logo" />
                        </a>
                        <p id="divider">&nbsp;|&nbsp;</p>
                        <p>For Companies</p>
                    </div>
                    <div
                        style={{
                            padding: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            visibility: !isFetching && error.message ? 'visible' : 'hidden'
                        }}
                    >
                        {error.message}
                    </div>
                    <div className="form_wrapper">
                        <LoginForm onSubmit={this.onFormSubmit} />
                    </div>
                    <br />
                    <p className="text_left">
                        Forgot Password?
                        <span className="mini_cta">
                            <a
                                href={`https://${getRedirectDomain(
                                    'accounts'
                                )}/login?fp=1&r=https://${window.location.host}/login`}
                            >
                                Click Here
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { isFetching, error } = state.client || {};
    const { checked, authenticated } = state.session || {};
    return { isFetching, error, checked, authenticated };
}
export default withRouter(connect(mapStateToProps)(LoginContainer));
