import React from 'react';
import { connect } from 'react-redux';
import {Field, reduxForm} from 'redux-form';

let AddSingleEmployeeForm = (props) => {

    const {handleSubmit, pristine, submitting} = props;

    return (
        <form className="l-form" onSubmit={handleSubmit}>
          <h2>Adding ONE(1) Employee: </h2>
          <p>&nbsp;</p>
          <p>Fill the form below and click submit to add one employee at a time</p>
          <p className="l-form__explanatory-text">
            NOTE: Dependants means your staff's family members that you bought plans for.
            1 husband/wife + 2 kids = 3 dependants.
            If you did not buy plans for family members, please leave blank
          </p>
          <p>&nbsp;</p>
          <Field component="input" className="form-input" type="text" name="first_name" placeholder="Firstname (required)"/>
          <Field component="input" className="form-input" type="text" name="last_name" placeholder="Lastname (required) "/>
          <Field component="input" className="form-input" type="email" name="email_address" placeholder="Email Address (required)"/>
          <Field component="input" className="form-input" type="number" name="phone_number" placeholder="Phone number (required)"/>
          <Field className="hidden" name="access_token" component="input" style={{display:"none"}}/>
          <Field className="hidden" name="client_id" component="input" style={{display:"none"}}/>
          <Field component="select" className="form-input" name="product_id">
            <option>Please select a plan for the Employee</option>
            {props.invoicePlans.map((plan)=>(
              <option key={plan.id} value={plan.id}>{plan.name}</option>
            ))}
          </Field>
          <Field component="input" className="form-input" type="number" name="no_of_dependant" placeholder="Number of dependants"/>
          <button className="form-button" disabled={pristine || submitting}>Submit</button>
        </form>
    );
};

AddSingleEmployeeForm = reduxForm({
    form:"addSingleEmployeeForm"
})(AddSingleEmployeeForm);

AddSingleEmployeeForm = connect(({session})=>({
    initialValues:{access_token:session.user.credentials.accessToken, client_id: session.user.companyInfo.id},
    enableReinitialize:true
}))(AddSingleEmployeeForm);

export default AddSingleEmployeeForm;