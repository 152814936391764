import React from 'react';
import {Switch, withRouter} from 'react-router-dom';
import PropsRoute from './utils/PropsRoute';
import EmployeeDetailsComponent from './EmployeeDetailComponent';
import EmployeeListComponent from './lists/EmployeeListComponent';

const EmployeeComponent = ({match, user}) => (
    <Switch>
        <PropsRoute exact path={`${match.url}/:staffId`} component={EmployeeDetailsComponent} user={user}/>
        <PropsRoute path={match.url} component={EmployeeListComponent} user={user}/>
    </Switch>
);

export default withRouter(EmployeeComponent);