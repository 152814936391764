import React from 'react';
import {MdSearch, MdFilterList} from 'react-icons/md';

class EnrolleeFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }

  handleSearchInput = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      this.props.searchEnrollees(this.state.searchTerm);
      e.target.blur();
    } else {
      this.setState({searchTerm: e.target.value});
    }
  }

  render() {
    const {searchTerm} = this.state;

    return (
      <div className='table-paginator' style={{justifyContent: "space-between"}}>
        <label htmlFor="active_status_filter" style={{margin: "0 0.6em", display: 'flex'}}>
          <MdSearch size='2.5em' title='Search' style={{cursor: 'pointer'}} onClick={() => this.props.searchEnrollees(searchTerm)}/>
          <input type='text' style={{height: '30px', width: '170px', paddingLeft: '0.5em', marginLeft: "1em"}}
            placeholder='Search...' value={searchTerm} onChange={this.handleSearchInput} onKeyPress={this.handleSearchInput}/>
        </label>
        
        <label htmlFor="active_status_filter" style={{margin: "0 0.6em", display: 'flex'}}>
          <MdFilterList size='2.5em' title='Filter'/>   
          <select id="active_status_filter" 
            value={this.props.status} 
            onChange={this.props.selectStatus} 
            style={{height: "30px", border: "1px solid #dedede", outline: "none", marginLeft: "1em"}}>
            <option value={''}>All</option>
            <option value={1}>Active</option>
            <option value={4}>Suspended</option>
            <option value={6}>Deactivated</option>
          </select>
        </label>
    </div>
    )
  }
}

export default EnrolleeFilter