import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as clientActions from '../actions/client';
import NotificationBanner from './boxes/NotificationBanner';
import Modal from './boxes/Modal';
import UpdateClientProfileForm from './forms/UpdateClientProfileForm';
import PreviousCards from './PreviousCards';
import LoadingScreen from './LoadingScreen';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import MainContent from './boxes/MainContent';
import PageNotFound from './PageNotFound';
// import COMPANY_STUB from '../company_icon.png';
import ImageInput from "./forms/inputs/ImageInput";


class ClientProfileComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openUpdateProfileModal:false,
            openPreviousCardsModal:false,
            clientLogo:""
        }
    }

    toggleUpdateProfileModal = () => {
          this.setState({
              openUpdateProfileModal: !this.state.openUpdateProfileModal
          })
    };

    togglePreviousCardsModal = () => {
        this.setState({
            openPreviousCardsModal: !this.state.openPreviousCardsModal
        })
    };

    renderLoading(){
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen/>
                </PageBody>
            </MainContent>
        )
    };

    onFormSubmit = (values, dispatch) => {
        const { user } = this.props;
        this.toggleUpdateProfileModal();
        dispatch(clientActions.updateClientProfile(
            values.email_address,
            values.phone_number,
            user.companyInfo.logo_filename,
            values.access_token,
            values.client_id
        ));
    };

    onImageUploaded = (img) => {
        const {dispatch, user} = this.props;
        this.setState({
            clientLogo:img
        });
        dispatch(clientActions.changeClientLogo(img,user.credentials.accessToken, user.companyInfo.id));
    };



    render(){

        const { openUpdateProfileModal, openPreviousCardsModal, clientLogo } = this.state;

        const { isFetching, error, alert, user, invalidated  } = this.props;

        if ( error && error.code === 404 ) return <PageNotFound/>;

        if(!isFetching && invalidated) return window.location.reload(true);
        if(!isFetching && !invalidated)
        return (
            <MainContent>
                {openUpdateProfileModal &&
                    <UpdateClientProfileModal
                        onFormSubmit={this.onFormSubmit}
                        onClose={this.toggleUpdateProfileModal}
                        client={user.companyInfo}
                        accessToken={user.credentials.accessToken}/>
                }
                {openPreviousCardsModal &&
                    <PreviousCardsModal
                        onClose={this.togglePreviousCardsModal}
                        clientId={user.companyInfo.id}
                        accessToken={user.credentials.accessToken}/>
                }
                <PageHeader title={`Your Company's Profile`}/>
                <PageBody>
                    {(!_.isEmpty(error) || alert) &&
                        <NotificationBanner
                            title={error.message ? "Error!" : "Notification!"}
                            message={error.message ? error.message : alert}
                            type={error.message ? "error" : "success"}/>
                    }
                    <div className="button-header">
                        <button
                            className="primary_button"
                            onClick={this.togglePreviousCardsModal}>
                                <i className="fa fa-credit-card" style={{color:"white"}}/>
                                {'  '}
                                View Available Payment Methods
                        </button>
                    </div>

                    <div className="dashboard_profile">
                        <div className="profile__img">
                            <ImageInput
                                width={250}
                                height={250}
                                onCropComplete={this.onImageUploaded}
                                placeholder={clientLogo || user.companyInfo.logoUrl}
                                cta="Upload New Logo"
                            />
                        </div>

                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                            <div style={{display:"flex", flexWrap:"wrap" }}>
                                <div className="profile__info">
                                    <p className="profile__label" >COMPANY NAME</p>
                                    <p className="profile__infotext">{`${user.companyInfo.name}`}</p>
                                    <p className="profile__label">ADDRESS</p>
                                    <p className="profile__infotext">{user.companyInfo.address}</p>
                                </div>
                                <div className="profile__info">
                                    <p className="profile__label" >EMAIL ADDRESS</p>
                                    <p className="profile__infotext">{`${user.companyInfo.email || 'N/A'}`}</p>
                                    <p className="profile__label">PHONE NUMBER</p>
                                    <p className="profile__infotext">{`${user.companyInfo.phoneNumber|| 'N/A'}`}</p>
                                </div>
                            </div>
                            <button className="primary_button" onClick={this.toggleUpdateProfileModal}>Edit and Save Company Info</button>
                        </div>
                        <br/>
                    </div>
                    {user.adminInfo.hasMultipleAccounts &&
                        <Link to={{
                            pathname: `/select-account`,
                            state: {
                                accounts: user.adminInfo.accounts,
                                accessToken: user.credentials.accessToken
                            }}}
                            style={{fontSize:"2rem",margin:"2rem"}}>
                            Click here to Select a different company
                        </Link>
                    }
                </PageBody>
            </MainContent>
        );
        return this.renderLoading();
    }
}


const UpdateClientProfileModal = (props) => {
  return (
      <Modal title="Update Company Info" onClose={props.onClose}>
          <UpdateClientProfileForm
              onSubmit={props.onFormSubmit}
              accessToken={props.accessToken}
              client={props.client}/>
      </Modal>
  )
};

const PreviousCardsModal = (props) => {
    return (
        <Modal title="Previous Cards" onClose={props.onClose}>
            <PreviousCards
                accessToken={props.accessToken}
                clientId={props.clientId}/>
        </Modal>
    )
  };

const mapStateToProps = ({client}) => {
    return {
    isFetching: client.isFetching,
    invalidated: client.invalidated,
    error: client.error ? Immutable.asMutable(client.error) : {},
    alert: client.alert
    }
};

export default connect(mapStateToProps)(ClientProfileComponent);