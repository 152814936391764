import { CLIENT_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    isFetching:false,
    isLoggedIn: false,
    invalidated:false,
    alert:"",
    error:{},
    overViewData:{
        noOfEnrollees:"",
        noOfPlans:"",
        amountPaid:""
    },
    usageData:{
        isFetching:false,
        data:[],
        summary:{}
    }
});

export default function clientReducer(state=initialState, action){
    switch(action.type){
        case CLIENT_ACTION_TYPES.LOGIN:
        case CLIENT_ACTION_TYPES.GET_OVERVIEW:
        case CLIENT_ACTION_TYPES.UPDATE_PROFILE:
        case CLIENT_ACTION_TYPES.CHANGE_LOGO:
            return Immutable(state).merge({
                isFetching: true
            });
        case CLIENT_ACTION_TYPES.GET_USAGE:
            return Immutable(state).merge({
               usageData: {isFetching:true}
            }, {deep: true});
        case CLIENT_ACTION_TYPES.LOGIN_FAIL:
        case CLIENT_ACTION_TYPES.GET_OVERVIEW_FAIL:
        case CLIENT_ACTION_TYPES.GET_USAGE_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        case CLIENT_ACTION_TYPES.LOGIN_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                isLoggedIn: true,
                error:{}
            }, {deep: true});
        case CLIENT_ACTION_TYPES.GET_OVERVIEW_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                overViewData:action.data,
                error:{}
            }, {deep: true});
        case CLIENT_ACTION_TYPES.GET_USAGE_SUCCESS:
            return Immutable(state).merge({
                usageData:{
                    isFetching: false,
                    data: action.data.results,
                    summary:action.data.summary
                }
            }, {deep: true});
        case CLIENT_ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                invalidated: true,
                error:{}
            });
        case CLIENT_ACTION_TYPES.CHANGE_LOGO_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                alert: action.data,
                error:{}
            });
        case CLIENT_ACTION_TYPES.CHANGE_LOGO_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: {
                    message: action.data,
                }
            });
        default:
            return state;
    }
}