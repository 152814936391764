import Cookies from 'js-cookie';

export const isDevelopment = () => {
    return process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'daara';
};

export const isStaging = () => {
    return process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'daara-staging';
};

export const isLocal = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const getAccountsDashboardUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:3000';
        case 'v3test':
        case 'development':
            return 'https://accounts.develop.reliancehmo.com';
        case 'daara':
            return 'https://accounts.daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://accounts.daara.staging.reliancehmo.com';
        case 'staging':
            return 'https://accounts.staging.reliancehmo.com';
        default:
            return 'https://accounts.reliancehmo.com';
    }
};

export const DOMAIN_COOKIE_NAME = isProduction() ? 'rat' : 'rat_' + process.env.REACT_APP_ENV;
export const DAARA_TOKEN_NAME = process.env.REACT_APP_DAARA_TOKEN_NAME;
export const DAARA_ACCOUNT_SERVICE_URL = process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL;
export const DAARA_CLIENT_SERVICE_URL = process.env.REACT_APP_DAARA_CLIENT_SERVICE_URL;
export const DAARA_INVOICE_SERVICE_URL = process.env.REACT_APP_DAARA_INVOICE_SERVICE_URL;
export const DAARA_PRODUCT_SERVICE_URL = process.env.REACT_APP_DAARA_PRODUCT_SERVICE_URL;
export const DAARA_FILE_SERVICE_URL = process.env.REACT_APP_DAARA_FILE_SERVICE_URL;
export const DAARA_ENROLLEE_SERVICE_URL = process.env.REACT_APP_DAARA_ENROLLEE_SERVICE_URL;

export const getCookie = (_name = DOMAIN_COOKIE_NAME) => {
    const cookie = Cookies.get(_name);
    return cookie;
};

export const getPaymentFrequencyFromDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let freq = end.getMonth() - start.getMonth() + 12 * (end.getFullYear() - start.getFullYear());

    switch (freq) {
        case 1:
            return 'Monthly';
        case 3:
            return 'Quarterly';
        case 12:
            return 'Yearly';
        case 6:
            return 'Biannualy';
        case 4:
            return 'Triannualy';
        default:
            return 'unknownFrequency';
    }
};

export const getDaaraInvoiceStatusIndicator = (status) => {
    switch (status) {
        case 'NO_PAYMENT':
            return 'Not Paid';
        case 'PARTIAL_PAYMENT':
            return 'Part Paid';
        default:
            return 'Payment Complete';
    }
};

export const getDaaraActiveStatus = (status) => {
    switch (parseInt(status)) {
        case 1:
            return 'ACTIVE';
        case 4:
            return 'SUSPENDED';
        case 6:
            return 'DEACTIVATED';
        default:
            return status;
    }
};
