import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';

let LoginForm = (props) => {

    const {handleSubmit, pristine, submitting} = props;

    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <Field component="input" type="text" name="email" placeholder="Email Address" required/>
                <Field component="input" type="password" name="password" placeholder="Password" required/>
                <button type="submit" disabled={pristine || submitting }>Log in</button>
            </form>
        </div>
    )
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func
};

LoginForm = reduxForm({
    form:'loginForm',
    destroyOnUnmount:false,
})(LoginForm);

export default LoginForm;