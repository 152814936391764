import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component{

    render(){
      return (
          <div className={`modal open`}>
              <div className="modal-content">
                  <span className="modal-close" onClick={this.props.onClose}>&times;</span>
                  <h3 className="modal-header">{this.props.title}</h3>
                  <div>
                      {this.props.children}
                  </div>

              </div>
          </div>
      )
    }

}

Modal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.object,
    onClose: PropTypes.func
};

export default Modal;