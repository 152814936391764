import React from 'react';
import PropTypes from 'prop-types';

const NotificationBanner = (props) => {
    return (
        <div className={`alert__banner ${props.type}`}>
            <h3>{props.title}</h3>
            <p>{props.message}</p>
        </div>
    )
};

NotificationBanner.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string
};

export default NotificationBanner;