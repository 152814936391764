import React from 'react';
import { connect } from 'react-redux';
import numberFormatter from 'number-formatter';
import { withRouter, Redirect } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import * as clientActions from '../actions/client';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from 'recharts';
import LoadingScreen from './LoadingScreen';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import MainContent from './boxes/MainContent';

class OverviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openProfile: false
        };
    }

    onOpenProfile = () => {
        this.setState({
            openProfile: !this.state.openProfile
        });
    };

    componentDidMount() {
        const { dispatch, user } = this.props;
        dispatch(clientActions.getOverViewData(user.credentials.accessToken, user.companyInfo.id));
        dispatch(clientActions.getUsageData(user.credentials.accessToken, 30, user.companyInfo.id));
    }

    renderLoading() {
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen />
                </PageBody>
            </MainContent>
        );
    }

    renderUsageLoading() {
        return (
            <div className="container content" style={{ height: '300px' }}>
                <LoadingScreen />
            </div>
        );
    }

    renderUsage(results, summary) {
        return (
            <div className="usage">
                <div className="usage-summary">
                    <div className="l_card">
                        <p className="smalltext">Health Chats</p>
                        <h1 className="big_num">{summary.total_questions_asked || 0}</h1>
                    </div>
                    <div className="l_card">
                        <p className="smalltext">Health Tips Read</p>
                        <h1 className="big_num">{summary.total_tips_read || 0}</h1>
                    </div>
                    <div className="l_card">
                        <p className="smalltext">Hospital Visits</p>
                        <h1 className="big_num">{summary.total_hospital_visits || 0}</h1>
                    </div>
                </div>

                <div className="usage-graph">
                    <UsageGraph data={results} />
                </div>
            </div>
        );
    }

    sortGraph = (days) => {
        const { dispatch, user } = this.props;
        dispatch(
            clientActions.getUsageData(user.credentials.accessToken, days, user.companyInfo.id)
        );
    };

    render() {
        const { isFetching, overViewData, usageData } = this.props;

        if (this.state.openProfile) return <Redirect to="/dashboard/profile" push />;

        if (isFetching) return this.renderLoading();

        return (
            <MainContent>
                <PageHeader
                    title="Overview"
                    showButton
                    buttonTitle="View Profile"
                    onButtonClick={this.onOpenProfile}
                />
                <PageBody>
                    <div className="company-dash">
                        <div className="company-info">
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    padding: '0rem 5rem',
                                    alignItems: 'center'
                                }}
                            >
                                <p style={{ marginLeft: '1rem' }}>Show:</p>
                                <select
                                    name="sort"
                                    onChange={(e) => this.sortGraph(e.target.value)}
                                >
                                    <option value="30">Last 30 Days</option>
                                    <option value="60">Last 60 Days</option>
                                    <option value="90">Last 90 Days</option>
                                    <option value="0">All</option>
                                </select>
                            </div>
                            {!usageData.isFetching
                                ? this.renderUsage(usageData.data, usageData.summary)
                                : this.renderUsageLoading()}
                        </div>
                        <hr />
                        <div className="l_card-holder">
                            <div className="l_card">
                                <p className="smalltext">Number of Employees</p>
                                <h1 className="big_num">{overViewData.no_of_enrollees || 0}</h1>
                            </div>
                            <div className="l_card">
                                <p className="smalltext">Number of plans</p>
                                <h1 className="big_num">{overViewData.no_of_plans || 0}</h1>
                            </div>
                            <div className="l_card">
                                <p className="smalltext">Total Paid (NGN)</p>
                                <h1 className="big_num">
                                    {numberFormatter('#,###.00', overViewData.amount_paid || 0)}
                                </h1>
                            </div>
                        </div>
                    </div>
                </PageBody>
            </MainContent>
        );
    }
}

const UsageGraph = (props) => {
    let height = 400,
        margins = { left: 5, right: 30, top: 20, bottom: 20 };

    return (
        <ResponsiveContainer width="100%" height={height}>
            <LineChart data={props.data} margin={margins}>
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="hospital_visits"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="questions_asked" stroke="#82ca9d" />
                <Line type="monotone" dataKey="tips_read" stroke="#89CBF0" />
            </LineChart>
        </ResponsiveContainer>
    );
};

const mapStateToProps = ({ client }) => ({
    isFetching: client.isFetching,
    overViewData: client.overViewData
        ? Immutable.asMutable(client.overViewData, { deep: true })
        : {},
    usageData: client.usageData ? Immutable.asMutable(client.usageData, { deep: true }) : {},
    error: client.error ? Immutable.asMutable(client.error) : {}
});

export default withRouter(connect(mapStateToProps)(OverviewComponent));
